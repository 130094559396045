.myDocViewer {
  min-height: 500px;
  max-height: 700px;
}

.myVideo {
  max-height: 500px;
}

.tryFormatMultilineString {
  white-space: pre-wrap;
}