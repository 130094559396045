.text-truncate-two-lines {
  line-height: 1.2;
  min-height: 38.4px; /* 2 * 1.2 * 16px */
}

.text-truncate-four-lines {
  line-height: 1.2;
  min-height: 76.8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: horizontal;
  overflow: hidden;
}

.text-truncate-four-lines-poll {
  line-height: 1.2;
  min-height: 38.4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  overflow: hidden;
}

.img-container {
  width: 128px;
  height: 64px;
}

.img-cover {
  width: 128px;
  height: 64px;
  object-fit: cover;
}

.img-cover-lg {
  width: 256px;
  height: 128px;
  object-fit: cover;
}

.img-cover-gal {
  width: 100%;
  min-width: 256px;
  height: 100%;
  min-height: 256px;
  object-fit: cover;
  object-position: center center;
}


